









































import { Vue, Component } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import ImagePreview from '@/components/ImagePreview.vue'

interface Pick {
  key_num: number
  register_cert: boolean,
  license: boolean,
  insurance: boolean,
  id_card: boolean,
  remark: string,
  image_urls: Array<string>
}

@Component({
  components: {
    HeaderBox,
    ImagePreview
  },
  filters: {
    filterStatus(value) {
      return value ? '有' : '没有'
    }
  }
})
export default class PickDetail extends Vue {
  pickDetail: any = null
  currentImages = []
  currentIndex = 0
  showPreviewWindow = false

  created() {
    const { center_car_id } = this.$route.query;
    this.getReviewInfo(center_car_id);
  }

  async getReviewInfo (center_car_id) {
    const { code, data } = await this.$rest.carManage.getReviewInfo(center_car_id);
    if (code !== 0) return;
    this.pickDetail = (data || {}).infos;
  }

  handleLookBigImages (urls, index) {
    this.currentIndex = index;
    this.currentImages = this.formatBigImages(urls);
    this.showPreviewWindow = true;
  }
}
